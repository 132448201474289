import React, {Component} from "react"
import {graphql, Link} from "gatsby"

import imgPage from "../images/headers/Logos_Mex_Jet.svg"
import Layout from "../components/layout.en"
import SEO from "../components/seo"
import style from "./mexjet.module.scss"
import Header from "../components/header";
import Img from "gatsby-image"
import {GetListEn} from "../hooks/jetListEn";

import Equipaje from "../images/mexjet/icon_equipaje.svg"
import Passajeros from "../images/mexjet/icon_pasajeros.svg"

import JetCard from "../images/mexjet/logo_JetCard.png"
import Equity from "../images/mexjet/foto_Equity.jpg"
import videoEquity from "../video/equity.mp4"
//SECURITY
import Wingman from "../images/icons/wingman_pro.png"
import Isbao from "../images/icons/ISBAO.png"

import Form from "../components/form.en";
import "video-react/dist/video-react.css"

import {ControlBar, Player} from "video-react";
//import VideoCovid from "../video/Master_AleCovid19_Draft2.mp4";

export default ({data}) => {
    const jetList = GetListEn();
    const extraInfo =
        [
            {name: '', mail: 'info@ale.mx', phone: '554 202 0920'},
        ];
    return (
        <Layout extraInfo={extraInfo}>
            <Header LogoPage={imgPage}/>
            <SEO title="MexJet"/>
            <Img fluid={data.portada.childImageSharp.fluid}/>
            <Form options={['MexJet: Fractional Aircraft/Jetcards (hrs)']}/>
            <div className={style.jetServices}>
                <div className={style.card}>
                    <img src={JetCard} alt="JetCard"/>
                    <p>Acquire only the hours you need without a time limit and with the possibility of exchanging to
                        equipment of other features.</p>
                    <a href="#jetcard">Learn More</a>
                </div>
                <div className={style.equity}>
                    <img src={Equity} alt="Equity"/>
                    <p>Equity allows you to own all the best of the desired aircraft, for only a fraction of the asset
                        and the fixed operating costs.</p>
                    <a href="#equity">Learn More</a>
                </div>
            </div>

            <PopCovid/>

            <div className={style.jetOur}>
                <h2>Our Fleet</h2>

                <div className={style.items}>
                    {jetList.map((item) => {

                        return (
                            <div className={`${style.item}`} key={item.node.frontmatter.slug}>
                                <div className={style.left}>
                                    <Img style={{maxHeight: '270px'}}
                                         fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}/>
                                    <p>{item.node.frontmatter.title}</p>

                                </div>

                                <div className={style.right}>
                                    <div className={style.images}>
                                        <Passajeros/>
                                        <Equipaje/>
                                        <p>
                                            <span>{item.node.frontmatter.pasajeros}</span>
                                            <span>{item.node.frontmatter.equipaje}</span>
                                        </p>
                                    </div>
                                    {(item.node.frontmatter.title !== "Citation M2 GEN2" && item.node.frontmatter.title  !== 'Citation CJ3+') ?
                                        <Link to={item.node.frontmatter.slug}>See more</Link>
                                        :
                                        <p className={style.soon}>Próximamente</p>
                                    }
                                </div>


                            </div>
                        )

                    })}
                </div>
            </div>

            <div className={style.jetImages}>

                <div className={style.title} id="jetcard">
                    <p>Jet Card</p>
                </div>
                <div className={`${style.infoBlock} ${style.logo}`}>
                    <img src={JetCard} alt="JetCard"/>
                    <div className={style.info}>
                        <ul>
                            <p><b>We value your time</b></p>
                            <p>Mexjet's Jetcard was the first pre-paid flight hours program launched in México. After its launch in 1997, as a pioneer of fractional aviation in México, we have perfected our Jetcard to be the entry product to executive aviation, acquiring only the hours you need, and debiting them without a time limit and with the possibility of exchanging to equipment of other features and choosing a program for business or leisure travel.</p>
                        </ul>
                    </div>
                </div>
                <div className={style.title} id="equity">
                    <p>Equity</p>
                </div>
                <div className={style.infoBlock}>
                    <div className={style.info}>
                        <ul>
                            <p><b>Fractioning has more value</b></p>
                            <p>For those who own an aircraft, or intend to acquire one, Mexjet Equity allows to invest in an asset of better features (e.g. larger, higher rank, newer, etc.), than the currently operated or the one wished to acquire. Fixed costs, inherent in the operation of any aircraft, increase the cost per flight hour when the hours used annually are few; Mexjet Equity allows you to own all the best of the desired aircraft, for only a fraction of the asset and fixed operating costs.</p>
                        </ul>
                    </div>
                    <div className={style.img}>
                        <img src={Equity} alt="equity"/>
                        <VideoEquity/>
                    </div>
                </div>
                <div className={style.title}>
                    <p>Safety</p>
                </div>
                <div className={style.jetSecurity}>
                    <p><b>Your safety is our priority</b></p>
                    <p>We make a constant investment to offer you a 100% safe operation, with the following certifications:</p>
                    <div className={style.logos}>
                        <div className="item">
                            <a href="https://ibac.org/is-bao" target="_blank" rel="noopener noreferrer">
                                <img src={Isbao} alt="IS-BAO"/>
                            </a>
                        </div>

                        <div id="wyvern">
                            <a href="https://app.wyvern.systems/public/directory/wingman" target="_blank"
                               rel="noopener noreferrer">
                                <img
                                    src={Wingman} alt="WYVERN Click-to-Verify"/>
                                <p>Click-to-Verify</p>
                            </a>
                        </div>
                    </div>
                </div>

                <div className={style.title}>
                    <p>BASES OF OPERATION</p>
                </div>
                <div className={style.jetSecurity}>
                <p>
                    <ul>
                        <li>Toluca</li>
                        <li>Monterrey</li>
                        <li>Guadalajara</li>
                        <li>León</li>
                        <li>Mérida</li>
                        </ul>
                        </p> 
                </div>
            </div>

        </Layout>
    )
}

export class PopCovid extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            vidStatus: false,
            player: null,
            active: true,
            playing: false
        };

        this.startVideo = this.startVideo.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.closeAlert = this.closeAlert.bind(this)
    }

    closeAlert() {
        this.setState({active: false})
        this.player.pause();
    }

    startVideo() {
        this.setState({vidStatus: true});
        this.player.play();
    }

    onEnded() {
        this.closeAlert();
    }

    render() {
        return (
            <div className={this.state.active ? `${style.popUpVideo} ${style.active}` : style.popUpVideo}>

            </div>
        )
    }
}

export class VideoEquity extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            vidStatus: false,
            player: null
        };
        this.startVideo = this.startVideo.bind(this);
        this.onEnded = this.onEnded.bind(this);
    }


    startVideo() {
        this.setState({vidStatus: true});
        this.player.play();
    }

    onEnded() {
        this.setState({vidStatus: false})
    }

    render() {
        return (
            <Player
                poster={Equity}
                onEnded={this.onEnded}
                className={this.state.vidStatus === true ? `${style.video} ${style.active}` : `${style.video}`}
                src={videoEquity}
                ref={player => {
                    this.player = player;
                }}
            >
                <ControlBar disableCompletely={false}/>
                <button className={style.button} onClick={this.startVideo}>Play Video</button>
            </Player>
        )
    }

}


export const query = graphql`   
    query ImagesMexJetEn {
    portada: file(relativePath: {eq: "mexjet/foto_portada.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
   
}`;





